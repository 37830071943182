*::selection {
  background: var(--ra-red);
  color: #fff;
}
*::-moz-selection {
  background: var(--ra-red);
  color: #fff;
}

*::-webkit-scrollbar {
  width: 3px;
  height: 0;
}
*::-webkit-scrollbar-track {
  background-color: #000;
}

*::-webkit-scrollbar-thumb {
  background: var(--ra-red);
  border: none;
}

html,
body {
  width: 100%;
  height: 100%;
  // background-color: #D1d1d1;

  // background: rgb(197, 221, 233);
  // background: linear-gradient(
  //   180deg,
  //   rgba(197, 221, 233, 1) 0%,
  //   rgba(197, 221, 233, 1) 24%,
  //   rgba(2, 149, 186, 1) 54%,
  //   rgba(1, 41, 78, 1) 77%,
  //   rgba(3, 20, 32, 1) 92%,
  //   rgba(0, 0, 0, 1) 100%
  // );

  // background-color: #000;

  // background: rgb(241, 243, 228);
  // background: linear-gradient(
  //   0deg,
  //   rgba(241, 243, 228, 1) 0%,
  //   rgba(236, 243, 88, 1) 24%,
  //   rgba(250, 250, 76, 1) 50%,
  //   rgba(235, 87, 80, 1) 100%
  // );

  background: rgb(204, 204, 204);
  background: linear-gradient(
    180deg,
    rgba(204, 204, 204, 1) 0%,
    rgba(209, 209, 209, 1) 34%,
    rgba(84, 79, 232, 0.6) 88%,
    rgba(0, 33, 255, 0.4) 100%
  );
}
