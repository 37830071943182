.nav{

    &__wrapper{
        position: fixed;
        top: 30px;
        left: 0;
        margin: 10px 20px;
        z-index: 100;
    }

    &__button{
        display: flex;
        flex-direction: row;
        text-wrap: nowrap;
        transition: color 0.2s ease-in-out;
        cursor: pointer;
        
        &.active{
            color: var(--ra-red);
            pointer-events: none;
        }
        @include hover {
            text-decoration: underline;
            text-decoration-line: underline;
            text-decoration-thickness: 1px;
            text-decoration-color: var(--ra-red);
          }
    }

    &__dash-wrapper{
        height: 33px;
        position: relative;
        top: 7px;
        overflow: hidden;
        width: 0;
        transition: width 0.2s ease-in-out;
        margin-right: 5px;
        // background-color: green;
        &.active{
            width: 30px;
        }

    }

    &__dash{
        width: 40px;
        height: 40px;
        left: -20px;
        top: -17px;
        border-bottom: 1px solid var(--ra-red);
        position: absolute;
        transform: rotate(-45deg);
    }
}

@include mobile{

    .nav{

        &__wrapper{
            margin: 10px ;
        }

        &__dash-wrapper{
            height: 20px;
            top: 5px;
            // background-color: green;
            &.active{
                width: 20px;
            }
    
        }
    
        &__dash{
            width: 30px;
            height: 30px;
            left: -15px;
            top: -15px;
        }

    }

 
}