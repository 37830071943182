.carousel {
  &__container {
    width: 100vw;
    height: var(--app-height);
    position: relative;
    overflow: hidden;
  }

  &__outer-wrapper {
    position: absolute;
    left: 0;
    transition: top 0.6s ease-in-out;
  }

  &__vignette {
    position: fixed;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    mask-image: radial-gradient(
      ellipse 50vw 50vw at 50% 50%,
      transparent 40%,
      black 100%
    );
    backdrop-filter: blur(5px);
    opacity: 0.6;
  }

  &__inner-wrapper {
    height: var(--app-height);
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: nowrap;
    // background-color: green;
    // overflow-x: auto;
    // overflow-y: visible;
    overflow-y: scroll;
    // pointer-events: none;
    transition: left 0.4s ease-in-out;
  }

  &__spacer {
    width: 55vh;
    min-width: 55vh;
  }

  &__record-outer {
    min-width: 55vh;
    width: 55vh;
    position: relative;
    perspective: 50vw;
    overflow: visible;
    z-index: 3;
    // perspective: 500px;
    // transform-style: preserve-3d;
    &.prev {
      z-index: 2;
    }
    &.left {
      z-index: 1;
    }
    &.next {
      z-index: 2;
    }
    &.right {
      z-index: 1;
    }
  }

  &__record-cover {
    height: 55vh;
    width: 55vh;
    background-color: #eeeeee;
    // perspective: 1000px;
    // transform-style: preserve-3d;
    transform-origin: center;
    transition: all 0.1s linear;
    top: 50%;
    left: 50%;
    position: absolute;
    pointer-events: all;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // mix-blend-mode: multiply;
    // opacity: 0;
    // &.filter{
    //     opacity: 0;
    //     mix-blend-mode: screen;
    //     // filter: contrast(30);
    //     // filter: saturate(3);
    //     filter: grayscale(100%) contrast(100) saturate(20%);
    //     &.active{
    //         opacity: 1;
    //     }
    // }

    &.mix {
      pointer-events: none;
    }
    &.left {
      left: 60%;
    }
    &.right {
      left: 40%;
    }
  }

  &__record-filter {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // transform: translateZ(-500px);
    // opacity: 0.3;
  }

  &__button {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 30vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    &.active {
      opacity: 1;
      pointer-events: all;
    }
    // div {
    //   display: inline-block;
    //   margin: 0 auto;
    //   transform-origin: center center;
    //   transition: transform 0.2s ease-in-out;
    // }
    &.prev {
      left: 0;
    }
    &.next {
      right: 0;
    }
    @include hover {
      div {
        transform: scale(1.2) translateY(-50%) rotate(45deg);
        border-color: var(--ra-red);
      }
    }
  }

  &__arrow {
    width: 2.5vw;
    height: 2.5vw;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    transform-origin: center;
    transition: transform 0.2s ease-in-out;
    &.next {
      right: 50px;
      border-right: 2px solid #fff;
      border-top: 2px solid #fff;
    }
    &.prev {
      left: 50px;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
    }
  }

  &__crop-wrapper {
    pointer-events: none;
    width: 60vh;
    height: 60vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.4s ease-in-out;
    z-index: 10;
    opacity: 0;
    &.active {
      opacity: 1;
    }
    &.disabled {
      height: 20px;
      width: 20px;
    }
  }

  &__crop {
    position: absolute;
    width: 10px;
    height: 10px;
    &.top {
      top: 0;
      border-top: 1px solid #000;
    }
    &.bottom {
      bottom: 0;
      border-bottom: 1px solid #000;
    }
    &.left {
      left: 0;
      border-left: 1px solid #000;
    }
    &.right {
      right: 0;
      border-right: 1px solid #000;
    }
  }

  &__titles {
    position: fixed;
    pointer-events: all;
    z-index: 10000;
    top: 20px;
    left: calc(50% - 27.5vh - 10px);
    max-width: calc(55vh + 20px);
    // background-color: red;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(20vh - 30px);
    &.active {
      opacity: 1;
      pointer-events: all;
    }
    a {
      @include hover {
        text-decoration: underline;
        text-decoration-line: underline;
        text-decoration-thickness: 1px;
        text-decoration-color: var(--ra-red);
      }
    }
  }

  &__title {
    &.label {
      margin-bottom: 6px;
      span {
        background-color: #000;
        color: #AAAAAA;
      }
    }
    span {
      background-color: #000;
      color: #fff;
    }
  }

  &__text-box {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    z-index: 200;
    // width: calc((100vw - 60vh) / 2 - 60px);
    margin: 20px;
    padding: 10px;
    transition: opacity 0.4s ease-in-out;
    max-height: calc(20vh - 70px);
    background-color: #000;
    color: #fff;
    
    left: calc(50% - 27.5vh - 30px);
    width: 55vh;
    top: calc(80vh + 15px);
    overflow-y: auto;

    &.active {
      opacity: 1;
      pointer-events: all;
    }

  }

  &__text-inner {
    padding-right: 10px;
    overflow-y: auto;
    max-height: calc(20vh - 50px);
  }

  &__listen {
    position: fixed;
    pointer-events: all;
    z-index: 10000;
    top: 80vh;
    left: calc(50% - 27.5vh );
    width: 55vh;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s ease-in-out;
    text-align: center;
    // overflow-y: auto;
    &.active {
      opacity: 1;
      pointer-events: all;
    }
    display: inline;
    a {
      h2 {
        color: #FFF;
        background-color: #000;
        display: inline;
        margin: 0 5px;
        @include hover {
          text-decoration: underline;
          text-decoration-line: underline;
          text-decoration-thickness: 1px;
          text-decoration-color: var(--ra-red);
        }
      }
    }
  }

  &__listen-title{
    display: inline-block;
    span{
      color: #AAAAAA;
      background-color: #000;
      margin: 0 5px 5px 0;
      display: inline;
    }
  }
}

@include mobile {
  .carousel {
    &__vignette {
      mask-image: radial-gradient(
        ellipse 180vw 100vw at 50% 40%,
        transparent 50%,
        black 100%
      );
      opacity: 1;
    }

    &__spacer {
      width: 100vw;
      min-width: 100vw;
      height: 100vw;
      min-height: 100vw;
    }
    &__crop-wrapper {
      display: none;
    }

    &__outer-wrapper {
      transition: top 0.6s ease-in-out, left 0.6s ease-in-out;
    }

    &__inner-wrapper {
      height: var(--app-height);
      width: 100vw;
      display: block;
      overflow-y: visible;
      // overflow-x: hidden;
      transition: top 0.4s ease-in-out, left 0.4s ease-in-out;
    }

    &__record-outer {
      height: calc(100vw - 60px);
      min-width: calc(100vw - 60px);
      width: calc(100vw - 60px);
      margin: 0;
      padding: 30px;
      // background-color: red;
      overflow-x: hidden;
    }

    &__record-cover {
      height: calc(100vw - 60px);
      width: calc(100vw - 60px);
      position: static;

      &.mix {
        pointer-events: none;
      }
      &.left {
        left: 60%;
      }
      &.right {
        left: 40%;
      }
    }

    &__button {
      width: 30vw;
      @include hover {
        div {
          transform: scale(1) translateY(-50%) rotate(45deg);
        }
      }
    }

    &__arrow {
      width: 25px;
      height: 25px;
      top: calc(50vw + 110px);
      &.next {
        right: 25px;
        border-right: 2px solid var(--ra-red);
      border-top: 2px solid var(--ra-red);
      }
      &.prev {
        left: 25px;
        border-left: 2px solid var(--ra-red);
      border-bottom: 2px solid var(--ra-red);
      }
    }

    &__text-box {
      width: calc(100vw - 62px);
      // margin: 20px;
      // padding: 10px;
      height: calc(10vh );
      max-height: none;
      background-color: #000;
      color: #fff;
      overflow-y: auto;
      top: auto;
      bottom: 0;
      left: 0;
    }

    &__listen {
      top: auto;
      bottom: calc(10vh + 30px);
      left: 0;
      width: calc(100vw - 40px);
      margin: 20px;
      text-align: left;
      // background-color: #000;
      // color: #fff;
      a {
        h2 {
      //     color: #fff;
          margin: 0 5px 0 0;
        }
      }
    }
    &__listen-title{
      span{
        margin-bottom: 3px;
      }
    }

    &__titles {
      top: auto;
      bottom: calc(10vh + 58px);
      left: 0;
      max-width: none;
      width: calc(100vw - 40px);
      margin: 20px 20px 30px 20px;
      height: auto;
      // &.mix {
      //   bottom: calc(10vh + 80px);
      // }
    }

    &__title {
      text-align: left;
      &.label{
        span{
          display: inline-block;
          margin-bottom: 3px;
        }
        
      }
      // background-color: #000;
      // color: #fff;
      // display: inline;
    }
  }
}
