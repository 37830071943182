.background {

  &__wrapper {
    width: 100vw;
    height: var(--app-height);
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  &__container{
    height: 100%;
    position: absolute;
    top: 0;
    transition: left 0.4s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  &__section{
    width: calc(100vw - 50px);
    height: var(--app-height);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-right: 50px;
    // &:first-child{
    //   color: rgb(6, 179, 6);
    // }
    // &:nth-child(2){
    //   color: rgb(2, 138, 2);
    // }
    // &:last-child{
    //   color: rgb(1, 68, 1);
    //   opacity: 0.4;
    // }
  }

  &__cell{
    width: 25%;
    height: 20%;
    flex-grow: 1;
    font-size: 10px;
    line-height: 6px;
    position: relative;
  }

  &__text{
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // color: #898989;
    // color: #535353;

    color: rgba(84,79,232,1);
    // color: rgb(6, 179, 6);
    // opacity: 0.2;
  }
}

@include mobile{

  .background {

    &__wrapper {
      display: none;
    }
  }
}